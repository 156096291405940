import React from 'react';
import Trix from 'trix';
import { TrixEditor } from "react-trix";
import { trixToolbar } from "./trixConfig"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

const StepOne = (props) => {

  function updateImagePreview(event) {
    const files = event.target.files;
    const image = files[0];

    let reader = new FileReader();
    reader.onload = function (file) {
      let img = new Image();
      img.src = file.target.result;

      props.handleOnChangeImageSrc(img.src);
    }
    reader.readAsDataURL(image);
    props.handleOnChangeImage(image);
  };


  // is bound one the color buttons to close the dialog
  Trix.ToolbarController.prototype.closeDialog = function (dialogElement) {
    const element = dialogElement
    const input = element.querySelector('input')
    // close the dialog
    element.removeAttribute('data-trix-active')
    element.classList.remove('trix-active')

    // this is the correct way to obtain the editor instance
    const editor = document.querySelector('trix-editor').editor

    editor.selectionManager.unlock()

    let innerHTML = document.querySelector('trix-editor').innerHTML.replace(/background-color: highlight;/, "")
    document.querySelector('trix-editor').innerHTML = " " + innerHTML // for ff we need to add an empty string
  }


  // Trix configuration
  Trix.config.textAttributes.underline = {
    inheritable: true,
    style: { textDecoration: 'underline' },
    parser: function (element) {
      return element.style.textDecoration === 'underline'
    },
  }

  Trix.config.blockAttributes.heading2 = {
    inheritable: true,
    tagName: 'h2',
  }
  Trix.config.blockAttributes.spacer = {
    inheritable: false,
    tagName: 'hr'
  }
  Trix.config.toolbar = {
    getDefaultHTML: () => {
      return trixToolbar()
    },
  }

  return (
    <div className="form-box">
      <div className="row">
        {
          props.message.state &&
          <div className={`columns large-12 ${props.message.state && 'error'}`}>
            <div className="callout alert">{props.message.text}</div>
          </div>
        }
      </div>
      <div className="row">
        <div className="columns small-12 medium-3">
          <label className="inline required">Betreff:</label>
        </div>
        <div className={`columns small-12 medium-9 ${(props.message.state && !props.subject) && 'error'}`}>
          <input
            type="text"
            value={props.subject}
            name="subject"
            id="subject"
            onChange={(event) => { props.handleOnChangeSubject(event.target.value) }}
          />
        </div>
      </div>
      <div className="row">
        <div className="columns medium-3">
          <label className="inline required">Nachricht:</label>
        </div>
        <div className={`columns small-12 medium-9 margin-bottom ${(props.message.state && !props.model) && 'error'}`}>
          <TrixEditor
            value={props.model}
            onChange={props.handleOnChangeModel}
          />
        </div>
      </div>
      <div className="row">
        <div className="columns medium-3">
          <label className="inline required">Beginnt am:</label>
        </div>
        <div className={`columns small-12 medium-9 end ${(props.message.state && !props.start_date) && 'error'}`}>
          <DatePicker
            dateFormat="DD.MM.YYYY"
            selected={props.start_date}
            onChange={props.handleOnChangeStartDate}
            placeholderText="Select a sart date"
          />
        </div>
      </div>
      <div className="row">
        <div className="columns medium-3">
          <label className="inline required">Endet am:</label>
        </div>
        <div className={`columns small-12 medium-9 ${(props.message.state && !props.end_date) && 'error'}`}>
          <DatePicker
            dateFormat="DD.MM.YYYY"
            selected={props.end_date}
            onChange={props.handleOnChangeEndDate}
            placeholderText="Select an end date"
          />
        </div>
      </div>
      <div className="row margin-top-l">
        <div className="columns small-12 medium-3">
          <label className="inline">Bild Upload (optional):</label>
          <small className="inline">(wenn möglich 16:9-Breitbild- Format wie z.B. 1920x1080 Pixel)</small>
        </div>
        <div className="columns small-12 medium-5 form-box">
          <div className="upload-box__form">
            <input
              className="upload-file-name"
              disabled="disabled"
              placeholder="Dateiname"
            />
            <div className="upload-button expand">
              Durchsuchen
            <input
                type="file"
                className="upload-input"
                name="notification[image]"
                id="notification_image"
                onChange={(event) => { updateImagePreview(event) }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns medium-3">
          <label className="inline">Vorschau:</label>
        </div>
        <div className='columns large-5 end'>
          <div className="notification-image--preview upload-box__image upload-box__image--placeholder">
            <img src={props.imageSrc} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns large-12">
          <div className="button-box button-box--right">
            <a className="button" onClick={(event) => { props.handleOnChangeTab(event, 'step_2') }}> Weiter</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepOne;