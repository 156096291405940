import 'isomorphic-fetch';

const API_HOST = 'http://localhost:3000/api/'; // 2nd should be production URL https://toyota-2016.bm.enterpriserails.de

//--------------------------
// HELPER
//--------------------------
function headers() {
  return {
    Accept:         'application/json',
    'Content-Type': 'application/json',
  };
}

function parseResponse(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return json;
  });
}

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => { return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`; })
    .join('&');
  return `${query.length ? '?' : ''}${query}`;
}

//--------------------------
// EXPORTS
//--------------------------
const API = {
  fetch(url, params = {}) {
    return fetch(`${API_HOST}${url}${queryString(params)}`, {
      method:  'GET',
      headers: headers(),
    })
      .then(parseResponse);
  },

  post(url, data) {
    const body = JSON.stringify(data);

    return fetch(`${API_HOST}${url}`, {
      method:  'POST',
      headers: headers(),
      body,
    })
      .then(parseResponse);
  },

  patch(url, data) {
    const body = JSON.stringify(data);

    return fetch(`${API_HOST}${url}`, {
      method:  'PATCH',
      headers: headers(),
      body,
    })
      .then(parseResponse);
  },

  delete(url) {
    return fetch(`${API_HOST}${url}`, {
      method:  'DELETE',
      headers: headers(),
    })
      .then(parseResponse);
  },
};

export default API;
