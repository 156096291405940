import React from 'react';

const NavigationTab = ({ title, number, step, navigation, handleOnChangeTab }) => {
  function activeStep() {
    return (step === navigation) ? 'notification-tab--active' : ''
  }

  return (
    <div className={`configurator-navigation__item ${activeStep()}`}>
      <a onClick={(event) => handleOnChangeTab(event, step)}>
        <span>{number}</span>
        {title}
      </a>
    </div>
  )
}

export default NavigationTab;