import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import API from '../Api/index';

import NavigationTab from './NavigationTab';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const API_EMPLOYEES = '/api/v1/notifications/employees.json';
const API_FILTER = '/api/v1/notifications/filters.json';

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current_path: '',
      employees: [],
      employeesChecked: [],
      employeesNotChecked: [],
      employeesShow: [],
      end_date: moment(),
      filters: {},
      filtersChecked: {},
      message: {
        state: '',
        text: '',
      },
      image: null,
      imageSrc: '',
      loadingSubmit: {
        state: '',
        text: '',
      },
      loadingEmployees: {
        state: '',
        text: '',
      },
      model: '',
      navigation: 'step_1',
      notificationId: '',
      start_date: moment(),
      searchTerm: '',
      subject: '',
      set_type: 'ChefNews',
    }

    this.fetchFilters = this.fetchFilters.bind(this);
    this.fetchEmployees = this.fetchEmployees.bind(this);

    this.handleOnChangeEndDate = this.handleOnChangeEndDate.bind(this);
    this.handleOnChangeImage = this.handleOnChangeImage.bind(this);
    this.handleOnChangeImageSrc = this.handleOnChangeImageSrc.bind(this);
    this.handleOnChangeMessage = this.handleOnChangeMessage.bind(this);
    this.handleOnChangeSearchList = this.handleOnChangeSearchList.bind(this);
    this.handleOnChangeStartDate = this.handleOnChangeStartDate.bind(this);
    this.handleOnChangeSubject = this.handleOnChangeSubject.bind(this);
    this.handleOnChangeTab = this.handleOnChangeTab.bind(this)
    this.handleOnChangeModel = this.handleOnChangeModel.bind(this)

    this.handleOnClickResetFilter = this.handleOnClickResetFilter.bind(this)

    this.handleSubmitNotification = this.handleSubmitNotification.bind(this);
    this.handleUpdateNotification = this.handleUpdateNotification.bind(this);
    this.handleOnChangeLoadingSubmit = this.handleOnChangeLoadingSubmit.bind(this);
    this.handleOnChangeLoadingEmployees = this.handleOnChangeLoadingEmployees.bind(this);

    this.handleInitialStoreEmployees = this.handleInitialStoreEmployees.bind(this);
    this.handleInitialStoreFilters = this.handleInitialStoreFilters.bind(this);
    this.handleInitialStoreEmployeesChecked = this.handleInitialStoreEmployeesChecked.bind(this);
    this.handleInitialStoreFiltersChecked = this.handleInitialStoreFiltersChecked.bind(this);

    this.handleUpdateFiltersArea = this.handleUpdateFiltersArea.bind(this);
    this.handleUpdateFiltersRegion = this.handleUpdateFiltersRegion.bind(this);
    this.handleUpdateFiltersRole = this.handleUpdateFiltersRole.bind(this);
    this.handleUpdateEmployee = this.handleUpdateEmployee.bind(this);
  }

  componentDidMount() {
    this.fetchFilters();
    this.fetchEmployees();

    const notificationData = $('.railsNotificationData').data();
    notificationData && this.updateStoreDataForEdit(notificationData);
  }

  /////////////////////////////////////////////////
  //////////////////// HANDLER ////////////////////
  /////////////////////////////////////////////////

  handleInitialStoreFilters(filters) {
    this.setState({
      filters
    })
  }

  handleInitialStoreEmployees(employees) {
    this.setState({
      employees
    })
  }


  handleInitialStoreFiltersChecked(filtersChecked) {
    this.setState({
      filtersChecked: { roles: [], hierarchy: filtersChecked['hierarchy'] }
    })
  }

  handleInitialStoreEmployeesChecked(employeesChecked) {
    employeesChecked.map(el => el.isChecked = false)
    _.sortBy(employeesChecked, ['area_id', 'company_reference'])

    this.setState({
      employeesChecked: [],
      employeesShow: employeesChecked,
    })
  }

  updateStoreDataForEdit({ end_date, current_path, image_src, model, notification_id, start_date, subject }) {
    this.setState({
      current_path,
      subject,
      model,
      notificationId: notification_id,
      start_date: moment(start_date),
      end_date: moment(end_date),
      imageSrc: image_src,
    })
  }

  handleOnChangeLoadingSubmit(state, text) {
    this.setState({
      loadingSubmit: {
        state,
        text
      }
    })
  }

  handleOnChangeLoadingEmployees(state, text) {
    this.setState({
      loadingEmployees: {
        state,
        text
      }
    })
  }

  handleOnChangeMessage(state, text) {
    this.setState({
      message: {
        state,
        text
      }
    })
  }

  handleOnChangeImageSrc(imageSrc) {
    this.setState({
      imageSrc
    })
  }

  handleOnChangeImage(image) {
    this.setState({
      image
    })
  }

  handleOnChangeModel(model) {
    this.setState({
      model
    })
  }

  handleOnChangeSubject(subject) {
    this.setState({
      subject
    })
  }

  handleOnChangeStartDate(start_date) {
    this.setState({
      start_date
    })
  }

  handleOnChangeEndDate(end_date) {
    this.setState({
      end_date
    })
  }

  handleOnChangeTab(event, nav) {
    event.preventDefault ? event.preventDefault() : event.returnValue = false;

    if (this.state.navigation === 'step_1' &&
      !this.state.subject ||
      !this.state.model ||
      !this.state.start_date ||
      !this.state.end_date
    ) {
      this.handleOnChangeMessage("error", "Bitte alle Felder ausfüllen")
    }
    else if (this.state.navigation === 'step_2' &&
      this.state.employeesChecked.length === 0) {
      this.handleOnChangeMessage("error", "Bitte wählen Sie mindestens einen Nutzer aus")
    }
    else {
      this.setState({
        message: '',
        navigation: nav
      })
    }

  }

  /////////////////////////////////////////////////
  //////////////////// FILTER /////////////////////
  /////////////////////////////////////////////////

  handleOnClickResetFilter() {
    let tempEmployeesShow = []
    let tempEmployeesChecked = JSON.parse(JSON.stringify(this.state.employees))

    tempEmployeesChecked.map(el => el.isChecked = true)
    tempEmployeesChecked = _.sortBy(tempEmployeesChecked, ['area_id', 'company_reference'])

    tempEmployeesShow.push(...tempEmployeesChecked)

    this.setState({
      employeesChecked: tempEmployeesChecked,
      employeesNotChecked: [],
      employeesShow: tempEmployeesShow,
      filtersChecked: JSON.parse(JSON.stringify(this.state.filters)),
      searchTerm: ""
    })
  }

  handleUpdateFiltersArea(region, area) {
    let tempFiltersChecked = this.state.filtersChecked;
    let tempEmployees = this.state.employees;
    let tempEmployeesChecked = this.state.employeesChecked;
    let tempEmployeesNotChecked = this.state.employeesNotChecked;
    let tempEmployeesShow = [];

    let checkedRegion = tempFiltersChecked.hierarchy.regions.find(x => x.id === region.id)

    let areaChecked = true;

    // If region exists already, check if current area is already in region
    if (checkedRegion) {
      // If current area is in checked regions --> remove area, else add it to region
      if (_.find(checkedRegion.areas, area)) {
        _.remove(checkedRegion.areas, area)
        areaChecked = false;
      } else {
        checkedRegion.areas.push(JSON.parse(JSON.stringify(area)))
      }
      // If no area in region exists --> remove region from filters
      checkedRegion.areas.length === 0 ? _.remove(tempFiltersChecked.hierarchy.regions, ['id', checkedRegion.id]) : ''
    } else {// else add region with the current area
      tempFiltersChecked.hierarchy.regions.push(JSON.parse(JSON.stringify({ id: JSON.parse(JSON.stringify(region.id)), identifier: JSON.parse(JSON.stringify(region.identifier)), areas: [JSON.parse(JSON.stringify(area))] })))
    }

    if (tempFiltersChecked.roles.length >= 1) {
      // if an area is checked look if a user is already checked in any another area and then do not check any other users of this area
      let userInArea = tempEmployees.filter(x => x.area_id === area.id);
      tempFiltersChecked.roles.length <= 1 ?
        userInArea = userInArea.filter(x => x.role === tempFiltersChecked.roles[0]) :
        ''
      if (areaChecked) {
        tempEmployeesChecked.push(...JSON.parse(JSON.stringify(userInArea)))
        tempEmployeesNotChecked = JSON.parse(JSON.stringify(_.without(tempEmployeesNotChecked, ...tempEmployeesNotChecked.filter(x => (x.area_id === area.id) && tempFiltersChecked.roles.some(y => y === x.role)))))
      } else {
        tempEmployeesNotChecked.push(...JSON.parse(JSON.stringify(userInArea)))
        tempEmployeesChecked = JSON.parse(JSON.stringify(_.without(tempEmployeesChecked, ...tempEmployeesChecked.filter(x => x.area_id === area.id))))
      }
    }

    tempEmployeesChecked.map(el => el.isChecked = true)
    tempEmployeesNotChecked.map(el => el.isChecked = false)

    tempEmployeesChecked = _.sortBy(tempEmployeesChecked, ['area_id', 'company_reference'])
    tempEmployeesNotChecked = _.sortBy(tempEmployeesNotChecked, ['area_id', 'company_reference'])

    tempEmployeesShow.push(...tempEmployeesChecked)
    tempEmployeesShow.push(...tempEmployeesNotChecked)

    this.setState({
      filtersChecked: tempFiltersChecked,
      employeesChecked: tempEmployeesChecked,
      employeesNotChecked: tempEmployeesNotChecked,
      employeesShow: tempEmployeesShow,
    })
    if (this.state.searchTerm) this.handleOnChangeSearchList(this.state.searchTerm, tempEmployeesChecked, tempEmployeesNotChecked)
  }

  handleUpdateFiltersRegion(region) {
    let tempFiltersChecked = this.state.filtersChecked;
    let tempEmployees = this.state.employees;
    let tempEmployeesChecked = this.state.employeesChecked;
    let tempEmployeesNotChecked = this.state.employeesNotChecked;
    let tempEmployeesShow = [];

    let regionChecked = true;

    if (tempFiltersChecked.hierarchy.regions.find(x => x.id === region.id)) {
      _.remove(tempFiltersChecked.hierarchy.regions, ['id', region.id])
      regionChecked = false;
    } else {
      tempFiltersChecked.hierarchy.regions.push(JSON.parse(JSON.stringify(region)))
    }

    if (tempFiltersChecked.roles.length >= 1) {
      // Get all users in the region with the roles set in filter
      let userInRegion = tempEmployees.filter(x => x.region_id === region.id);
      tempFiltersChecked.roles.length <= 1 ?
        userInRegion = userInRegion.filter(x => x.role === tempFiltersChecked.roles[0]) :
        ''

      // If a region is checked, look if a user is already checked in any another region and then do not check any other users of this region
      if (regionChecked) {
        tempEmployeesChecked.push(...JSON.parse(JSON.stringify(userInRegion)))
        tempEmployeesNotChecked = JSON.parse(JSON.stringify(_.without(tempEmployeesNotChecked, ...tempEmployeesNotChecked.filter(x => (x.region_id === region.id) && tempFiltersChecked.roles.some(y => y === x.role)))))
      } else {
        tempEmployeesNotChecked.push(...JSON.parse(JSON.stringify(userInRegion)))
        tempEmployeesChecked = JSON.parse(JSON.stringify(_.without(tempEmployeesChecked, ...tempEmployeesChecked.filter(x => x.region_id === region.id))))
      }
    }

    tempEmployeesChecked.map(el => el.isChecked = true)
    tempEmployeesNotChecked.map(el => el.isChecked = false)

    tempEmployeesChecked = _.sortBy(tempEmployeesChecked, ['area_id', 'company_reference'])
    tempEmployeesNotChecked = _.sortBy(tempEmployeesNotChecked, ['area_id', 'company_reference'])

    tempEmployeesShow.push(...tempEmployeesChecked)
    tempEmployeesShow.push(...tempEmployeesNotChecked)

    this.setState({
      filtersChecked: tempFiltersChecked,
      employeesChecked: tempEmployeesChecked,
      employeesNotChecked: tempEmployeesNotChecked,
      employeesShow: tempEmployeesShow,
    })
    if (this.state.searchTerm) this.handleOnChangeSearchList(this.state.searchTerm, tempEmployeesChecked, tempEmployeesNotChecked)
  }

  handleUpdateFiltersRole(role) {
    let tempFiltersChecked = this.state.filtersChecked;
    let tempEmployees = this.state.employees;
    let tempEmployeesChecked = [];
    let tempEmployeesNotChecked = [];
    let tempEmployeesShow = [];

    // If clicked role already exists --> uncheck it
    if (tempFiltersChecked.roles.find(roleName => roleName === role)) {
      tempFiltersChecked.roles.splice(_.indexOf(tempFiltersChecked.roles, role), 1)
    } else {// else --> check it
      tempFiltersChecked.roles.push(JSON.parse(JSON.stringify(role)))
    }

    let hasFilter = false;
    tempEmployees.map(employee => {
      tempFiltersChecked.roles.map(role => {
        if (employee.role.indexOf(role) !== -1) {
          tempFiltersChecked.hierarchy.regions.map(region => {
            if (employee.region_id === region.id) {
              region.areas.map(area => {
                if (employee.area_id === area.id) {
                  hasFilter = true;
                }
              })
            }
          })
        }
      })

      hasFilter ?
        tempEmployeesChecked.push(JSON.parse(JSON.stringify(employee))) :
        tempEmployeesNotChecked.push(JSON.parse(JSON.stringify(employee)))
      hasFilter = false;
    })

    tempEmployeesChecked.map(el => el.isChecked = true)
    tempEmployeesNotChecked.map(el => el.isChecked = false)

    tempEmployeesChecked = _.sortBy(tempEmployeesChecked, ['area_id', 'company_reference'])
    tempEmployeesNotChecked = _.sortBy(tempEmployeesNotChecked, ['area_id', 'company_reference'])

    tempEmployeesShow.push(...tempEmployeesChecked)
    tempEmployeesShow.push(...tempEmployeesNotChecked)

    this.setState({
      filtersChecked: tempFiltersChecked,
      employeesChecked: tempEmployeesChecked,
      employeesNotChecked: tempEmployeesNotChecked,
      employeesShow: tempEmployeesShow,
    })
    if (this.state.searchTerm) this.handleOnChangeSearchList(this.state.searchTerm, tempEmployeesChecked, tempEmployeesNotChecked)
  }

  handleOnChangeSearchList(searchTerm, employeesChecked, employeesNotChecked) {
    let tempEmployeesShow = []
    tempEmployeesShow.push(...employeesChecked)
    tempEmployeesShow.push(...employeesNotChecked)

    const tempSearchTerm = searchTerm.toLowerCase();

    tempEmployeesShow = _.filter(tempEmployeesShow,
      function (empl) {
        return empl.first_name.toLowerCase().includes(tempSearchTerm) ||
          empl.last_name.toLowerCase().includes(tempSearchTerm) ||
          (empl.first_name.toLowerCase() + " " + empl.last_name.toLowerCase()).includes(tempSearchTerm) ||
          empl.employee_number.toLowerCase().includes(tempSearchTerm) ||
          empl.company_reference.toLowerCase().includes(tempSearchTerm) ||
          empl.company_name.toLowerCase().includes(tempSearchTerm)
      }
    )

    this.setState({
      searchTerm,
      employeesShow: tempEmployeesShow,
    })
  }

  handleUpdateEmployee(employee) {
    let tempFiltersChecked = this.state.filtersChecked;
    let tempFilters = this.state.filters;

    let tempEmployeesChecked = this.state.employeesChecked;
    let tempEmployeesNotChecked = this.state.employeesNotChecked;
    let tempEmployeesShow = [];

    if (tempEmployeesChecked.find(empl => empl.id === employee.id)) {
      tempEmployeesChecked = tempEmployeesChecked.filter(function (el) { return el.id !== employee.id });
      tempEmployeesNotChecked.push(JSON.parse(JSON.stringify(employee)));
    } else {
      tempEmployeesNotChecked = tempEmployeesNotChecked.filter(function (el) { return el.id !== employee.id });
      tempEmployeesChecked.push(JSON.parse(JSON.stringify(employee)));
    }

    tempEmployeesChecked.map(el => el.isChecked = true)
    tempEmployeesNotChecked.map(el => el.isChecked = false)

    tempEmployeesChecked = _.sortBy(tempEmployeesChecked, ['area_id', 'company_reference'])
    tempEmployeesNotChecked = _.sortBy(tempEmployeesNotChecked, ['area_id', 'company_reference'])

    tempEmployeesShow.push(...tempEmployeesChecked)
    tempEmployeesShow.push(...tempEmployeesNotChecked)

    // If employee is not checked but will be checked
    if (!employee.isChecked) {
      // If region of employee is not in checked filter regions
      if (!tempFiltersChecked.hierarchy.regions.some(x => x.id === employee.region_id)) {
        const currentRegion = _.omit(tempFilters.hierarchy.regions.find(x => x.id === employee.region_id), 'areas')
        currentRegion.areas = [];

        const currentArea = tempFilters.hierarchy.regions.find(x => x.id === employee.region_id).areas.find(x => x.id === employee.area_id)
        currentRegion.areas.push(JSON.parse(JSON.stringify(currentArea)));

        tempFiltersChecked.hierarchy.regions.push(JSON.parse(JSON.stringify(currentRegion)))
      } // If area of employee is not in checked filter areas
      else if (!tempFiltersChecked.hierarchy.regions.some(x => x.areas.some(y => y.id === employee.area_id))) {
        const currentRegion = tempFiltersChecked.hierarchy.regions.find(x => x.id === employee.region_id);
        const currentArea = tempFilters.hierarchy.regions.find(x => x.id === employee.region_id).areas.find(x => x.id === employee.area_id)

        currentRegion.areas.push(JSON.parse(JSON.stringify(currentArea)));
      }
    } // If employee is checked but will be unchecked
    else if (employee.isChecked) {
      // If is last user of region --> uncheck region
      if (tempEmployeesChecked.filter(x => x.region_id === employee.region_id).length <= 0) {
        _.remove(tempFiltersChecked.hierarchy.regions, ['id', employee.region_id])
      }// If is last user of area --> uncheck area
      else if (tempEmployeesChecked.filter(x => x.area_id === employee.area_id).length <= 0) {
        _.remove(tempFiltersChecked.hierarchy.regions.find(x => x.id === employee.region_id).areas, ['id', employee.area_id])
      }
    }

    this.setState({
      employeesChecked: tempEmployeesChecked,
      employeesNotChecked: tempEmployeesNotChecked,
      employeesShow: tempEmployeesShow,
      filtersChecked: tempFiltersChecked,
    })
    if (this.state.searchTerm) this.handleOnChangeSearchList(this.state.searchTerm, tempEmployeesChecked, tempEmployeesNotChecked)
  }


  /////////////////////////////////////////////////
  //////////////////// REQUESTS ///////////////////
  /////////////////////////////////////////////////

  fetchEmployees() {
    const token = $("meta[name='csrf-token']").attr("content");

    const updateEmployees = this.handleInitialStoreEmployees
    const updateEmployeesChecked = this.handleInitialStoreEmployeesChecked
    const changeLoadingEmployees = this.handleOnChangeLoadingEmployees

    changeLoadingEmployees('', 'Bitte warten, Nutzer werden geladen.');

    if (!window.location.origin) { // Some browsers (mainly IE) does not have this property, so we need to build it manually...
      window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
    }

    $.ajax({
      makeBaseAuth: function (token) {
        var token = token;
        var hash = "";
        if (btoa) {
          hash = btoa(token);
        }
        return "Basic " + hash;
      },
      beforeSend: function (xhr) {
        return xhr.setRequestHeader('Authorization', this.makeBaseAuth(token));
      },
      type: 'GET',
      url: `${window.location.origin}${API_EMPLOYEES}`,
      error: function (xhr) {
        changeLoadingEmployees('alert', `Ein Fehler ist aufgetreten: ${xhr}`);
      },
      success: function (data, that) {
        updateEmployees(data);
        updateEmployeesChecked(JSON.parse(JSON.stringify(data)));
        changeLoadingEmployees('', '');
      }
    });
  };

  fetchFilters() {
    const token = $("meta[name='csrf-token']").attr("content");

    const updateFilters = this.handleInitialStoreFilters
    const updateFiltersChecked = this.handleInitialStoreFiltersChecked

    if (!window.location.origin) { // Some browsers (mainly IE) does not have this property, so we need to build it manually...
      window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
    }

    $.ajax({
      makeBaseAuth: function (token) {
        var token = token;
        var hash = "";
        if (btoa) {
          hash = btoa(token);
        }
        return "Basic " + hash;
      },
      beforeSend: function (xhr) {
        return xhr.setRequestHeader('Authorization', this.makeBaseAuth(token));
      },
      type: 'GET',
      url: `${window.location.origin}${API_FILTER}`,
      error: function (xhr) {

      },
      success: function (data) {
        updateFiltersChecked(JSON.parse(JSON.stringify(data)))
        updateFilters(data);
      }
    });
  };

  handleSubmitNotification() {
    const { employeesChecked, filtersChecked, model, subject, imageSrc, start_date, end_date, set_type } = this.state;
    const token = $("meta[name='csrf-token']").attr("content");

    const changeLoadingSubmit = this.handleOnChangeLoadingSubmit

    changeLoadingSubmit('info', "Bitte warten. Der Vorgang wird verarbeitet.")
    $.ajax({
      type: 'POST',
      url: "/notifications/create",
      data: {
        "authenticity_token": token,
        "notification": {
          "regions": filtersChecked.hierarchy.regions,
          "subject": subject,
          "message": model,
          "start_date": String(start_date),
          "end_date": String(end_date),
          "image": imageSrc,
          "users": employeesChecked,
          "set_type": set_type,
        },
      },
      error: function (xhr) {
        changeLoadingSubmit('alert', `Ein Fehler ist aufgetreten: ${xhr}`)
      },
      success: function (data) {
        changeLoadingSubmit('success', "Benachrichtigung wurde erfolgreich erstellt!")
      }
    });
  }

  handleUpdateNotification() {
    const { employeesChecked, filtersChecked, model, notificationId, subject, imageSrc, start_date, end_date, set_type } = this.state;
    const token = $("meta[name='csrf-token']").attr("content");

    const changeLoadingSubmit = this.handleOnChangeLoadingSubmit

    changeLoadingSubmit('info', "Bitte warten. Der Vorgang wird verarbeitet.")
    $.ajax({
      type: 'PUT',
      url: `/notifications/update/${notificationId}`,
      data: {
        "authenticity_token": token,
        "notification": {
          "regions": filtersChecked.hierarchy.regions,
          "subject": subject,
          "message": model,
          "start_date": String(start_date),
          "end_date": String(end_date),
          "image": imageSrc,
          "users": employeesChecked,
          "set_type": set_type,
        },
      },
      error: function (xhr) {
        changeLoadingSubmit('alert', `Ein Fehler ist aufgetreten: ${xhr}`)
      },
      success: function (data) {
        changeLoadingSubmit('success', "Benachrichtigung wurde erfolgreich erstellt!")
      }
    });
  }

  render(props) {
    return (
      <div>
        <div className="row configurator-navigation">
          <NavigationTab title={'Nachricht erstellen'} number={1} step={'step_1'} navigation={this.state.navigation} handleOnChangeTab={this.handleOnChangeTab} />
          <NavigationTab title={'Empfänger'} number={2} step={'step_2'} navigation={this.state.navigation} handleOnChangeTab={this.handleOnChangeTab} />
          <NavigationTab title={'Vorschau'} number={3} step={'step_3'} navigation={this.state.navigation} handleOnChangeTab={this.handleOnChangeTab} />
        </div>

        { this.state.navigation === 'step_1' &&
          <StepOne
            end_date={this.state.end_date}
            image={this.state.image}
            imageSrc={this.state.imageSrc}
            message={this.state.message}
            model={this.state.model}
            navigation={this.state.navigation}
            start_date={this.state.start_date}
            subject={this.state.subject}
            handleOnChangeEndDate={this.handleOnChangeEndDate}
            handleOnChangeImage={this.handleOnChangeImage}
            handleOnChangeImageSrc={this.handleOnChangeImageSrc}
            handleOnChangeModel={this.handleOnChangeModel}
            handleOnChangeStartDate={this.handleOnChangeStartDate}
            handleOnChangeSubject={this.handleOnChangeSubject}
            handleOnChangeTab={this.handleOnChangeTab}
          />
        }
        { this.state.navigation === 'step_2' &&
          <StepTwo
            employeesChecked={this.state.employeesChecked}
            employeesNotChecked={this.state.employeesNotChecked}
            employeesShow={this.state.employeesShow}
            employees={this.state.employees}
            filters={this.state.filters}
            filtersChecked={this.state.filtersChecked}
            message={this.state.message}
            loadingEmployees={this.state.loadingEmployees}
            searchTerm={this.state.searchTerm}
            users={this.state.users}
            handleOnChangeSearchList={this.handleOnChangeSearchList}
            handleOnChangeTab={this.handleOnChangeTab}
            handleOnClickResetFilter={this.handleOnClickResetFilter}
            handleUpdateEmployee={this.handleUpdateEmployee}
            handleUpdateFiltersArea={this.handleUpdateFiltersArea}
            handleUpdateFiltersRegion={this.handleUpdateFiltersRegion}
            handleUpdateFiltersRole={this.handleUpdateFiltersRole}
          />
        }
        { this.state.navigation === 'step_3' &&
          <StepThree
            current_path={this.state.current_path}
            end_date={this.state.end_date}
            handleOnChangeTab={this.handleOnChangeTab}
            image={this.state.image}
            imageSrc={this.state.imageSrc}
            loadingSubmit={this.state.loadingSubmit}
            model={this.state.model}
            start_date={this.state.start_date}
            subject={this.state.subject}
            handleSubmitNotification={this.handleSubmitNotification}
            handleUpdateNotification={this.handleUpdateNotification}
          />
        }

      </div>);
  }
}

Notification.propTypes = {
  subject: PropTypes.string,
  message: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string
};
export default Notification
