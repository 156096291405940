import Trix from 'trix'

export const trixLang = () => {
  return {
    attachFiles: 'Datei einfügen',
    bold: 'Fett',
    bullet: 'Liste',
    byte: 'Byte',
    bytes: 'Bytes',
    captionPlaceholder: 'Bildunterschrift hinzufügen…',
    code: 'Code',
    colors: 'Textfarbe',
    heading1: 'Überschrift 1',
    heading2: 'Überschrift 2',
    spacer: 'Horizontale Linie',
    indent: 'Einzug vergrössern',
    italic: 'Kursiv',
    link: 'Link',
    number: 'Aufzählung',
    outdent: 'Einzug verkleinern',
    quote: 'Zitat',
    redo: 'Wiederherstellen',
    remove: 'Entfernen',
    strike: 'Durchgestrichen',
    underline: 'Unterstrichen',
    undo: 'Rückgängig',
    unlink: 'Link entfernen',
    url: 'URL',
    urlPlaceholder: 'URL eingeben…',
    GB: 'GB',
    KB: 'KB',
    MB: 'MB',
    PB: 'PB',
    TB: 'TB',
    decrease_nesting: 'Ebene verringern',
    increase_nesting: 'Ebene vergrößern',
  }
}

export const trixToolbar = () => {
  // Color Buttons
  const colorMap = {
    green_light: "rgb(99, 188, 111)",
    green: "rgb(68, 167, 97)",
    turquoise_light: "rgb(36, 187, 156)",
    turquoise: "rgb(17, 167, 133)",
    blue_light: "rgb(87, 171, 208)",
    blue_light_two: "rgb(64, 141, 183)",
    blue: "rgb(48, 129, 199)",
    blue_two: "rgb(43, 105, 174)",
    purple_light: "rgb(147, 102, 182)",
    purple: "rgb(85, 57, 128)",
    yellow_light: "rgb(246, 218, 108)",
    yellow: "rgb(249, 198, 54)",
    orange_light: "rgb(250, 161, 56)",
    orange: "rgb(242, 123, 63)",
    red_light: "rgb(225, 83, 71)",
    red: "rgb(183, 53, 52)",
    black: "rgb(0, 0, 0)",
  }

  let colorButtons = ''

  for (let color in colorMap) {
    Trix.config.textAttributes[color] = {
      inheritable: true,
      style: { color: colorMap[color] },
    }

    // Note: declaring parser function in object above will render element always with color black
    const c = Trix.config.textAttributes[color]
    c.parser = function (element) {
      return element.style.color === c.style.color
    }

    colorButtons += '<input type="button" class="trix-button trix-button--dialog trix-button--color" data-trix-method="closeDialog" data-trix-attribute="' + color + '" style="background-color: ' + colorMap[color] + '" />'
  }

  const lang = trixLang()
  return `
    <div class="trix-button-row">
      <span
        class="trix-button-group trix-button-group--text-tools"
        data-trix-button-group="text-tools">
        <button
          type="button"
          class="trix-button trix-button--icon trix-button--icon-bold"
          data-trix-attribute="bold"
          data-trix-key="b"
          title="${lang.bold}"
          tabindex="-1">
          ${lang.bold}
        </button>
        <button type="button" class="trix-button" data-trix-attribute="underline" title="${lang.underline}">
        <span style="text-decoration: underline">U</span>
        </button>
        <button
          type="button"
          class="trix-button
          trix-button--icon
          trix-button--icon-italic"
          data-trix-attribute="italic"
          data-trix-key="i" title="Italic"
          tabindex="-1">
          Italic
        </button>
        <button
          type="button"
          class="trix-button
          trix-button--icon
          trix-button--icon-link"
          data-trix-attribute="href"
          data-trix-action="link"
          data-trix-key="k"
          title="Link"
          tabindex="-1">
          Link
          </button>
          <button 
            type="button" 
            class="trix-button" 
            data-trix-action="colors" 
            title="${lang.colors}">
            ${lang.colors}
          </button>
      </span>
      <span
        class="trix-button-group trix-button-group--block-tools"
        data-trix-button-group="block-tools">
        <button
          type="button"
          class="trix-button"
          data-trix-attribute="heading1"
          title="${lang.heading1}"
          tabindex="-1">
          ${lang.heading1}
        </button>
        <button
          type="button"
          class="trix-button"
          data-trix-attribute="heading2"
          title="${lang.heading2}"
          tabindex="-1">
          ${lang.heading2}
        </button>
        <button
          type="button"
          class="trix-button"
          data-trix-attribute="spacer"
          title="${lang.spacer}"
          tabindex="-1">
          ${lang.spacer}
        </button>
        <button 
          type="button" 
          class="trix-button trix-button--icon trix-button--icon-quote" 
          data-trix-attribute="quote" 
          title="${lang.quote}" 
          tabindex="-1">
          ${lang.quote}
        </button>
        <button
          type="button"
          class="trix-button trix-button--icon trix-button--icon-bullet-list"
          data-trix-attribute="bullet"
          title="${lang.bullet}"
          tabindex="-1">
          ${lang.bullets}
        </button>
        <button
          type="button"
          class="trix-button trix-button--icon trix-button--icon-number-list"
          data-trix-attribute="number"
          title="${lang.number}"
          tabindex="-1">
          ${lang.numbers}
        </button>
        <button 
          type="button" 
          class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" 
          data-trix-action="decreaseNestingLevel" 
          title="${lang.decrease_nesting}" 
          tabindex="-1">
          ${lang.decrease_nesting}
        </button>
        <button 
          type="button" 
          class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" 
          data-trix-action="increaseNestingLevel" 
          title="${lang.increase_nesting}" 
          tabindex="-1">
          ${lang.increase_nesting}
        </button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span
        class="trix-button-group trix-button-group--history-tools"
        data-trix-button-group="history-tools">
        <button
          type="button"
          class="trix-button trix-button--icon trix-button--icon-undo"
          data-trix-action="undo"
          data-trix-key="z"
          title="${lang.undo}"
          tabindex="-1">
          ${lang.undo}
        </button>
        <button
          type="button"
          class="trix-button trix-button--icon trix-button--icon-redo"
          data-trix-action="redo"
          data-trix-key="shift+z"
          title="${lang.redo}"
          tabindex="-1">
          ${lang.redo}
        </button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div
        class="trix-dialog trix-dialog--link"
        data-trix-dialog="href"
        data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input
            type="text"
            name="href"
            class="trix-input trix-input--dialog"
            placeholder="${lang.urlPlaceholder}"
            aria-label="${lang.url}"
            required
            data-trix-input
          />
          <div class="trix-button-group">
            <input
              type="button"
              class="trix-button trix-button--dialog"
              value="${lang.link}"
              data-trix-method="setAttribute"
            />
            <input
              type="button"
              class="trix-button trix-button--dialog"
              value="${lang.unlink}"
              data-trix-method="removeAttribute"
            />
          </div>
        </div>
      </div>
      <div class="trix-dialog trix-dialog--colors" data-trix-dialog="colors" data-trix-dialog-attribute="colors">
        <div class="trix-dialog__color-fields">
          <div class="trix-button-group">
            ${colorButtons}
          </div>
        </div>
      </div>
    </div>
  `
}
