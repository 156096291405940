import _ from 'lodash';
import * as types from './Types';
import API from '../Api/index';

const API_EMPLOYEES = 'v1/notifications/employees.json';

function fetchEmployeesFailure(error) {
  return {
    type: types.EMPLOYEES_FETCH_FAILURE,
    error,
  };
}

function fetchEmployeesRequest() {
  return {
    type:      types.EMPLOYEES_FETCH_REQUEST,
    isLoading: true,
  };
}

function fetchEmployeesSuccess(employees) {
  return {
    type:      types.EMPLOYEES_FETCH_SUCCESS,
    employees,
    isLoading: false,
  };
}

const fetchEmployees = () => {
  return (dispatch) => {
    dispatch(fetchEmployeesRequest());

    return API.fetch(`${API_EMPLOYEES}`)
      .then((json) => {
        return console.log(json);
      })
      .catch((errors) => { return dispatch(fetchEmployeesFailure(errors)); });
  };
};