import React from 'react';
import _ from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List, areEqual } from 'react-window'


const StepTwo = (props) => {
  const Headline = ({index, style}) => (
    <div className="notification-receiver__listHeadline" key={`headline-${index}`}>
      <div className="row">
        <div className="columns large-2 large-offset-1">Vorname</div>
        <div className="columns large-2">Nachname</div>
        <div className="columns large-1">VKNR</div>
        <div className="columns large-1">Funktion</div>
        <div className="columns large-1">HDLNR</div>
        <div className="columns large-4">Händler</div>
      </div>
    </div>
  )

  const Row = React.memo(({ data, index, style }) => {
    const item = props.employeesShow[index];

    return (
      <div className="notification-receiver__employee" key={`employee_${index}`} style={style}>
      <label htmlFor={`employee_${item.id} ${index}`} className="">
        <div className="row">
          <div className="columns small-1">
            <div className="checkbox">
              <input
                id={`employee_${item.id}`}
                type="checkbox"
                value="0"
                name={`area[${item.id}]`}
                checked={item.isChecked ? 'checked' : ''}
                onChange={(e) => { props.handleUpdateEmployee(item) }}
              />
              <label htmlFor={`employee_${item.id}`} />
            </div>
          </div>
          <span className="columns small-2"> {item.first_name} </span>
          <span className="columns small-2"> {item.last_name} </span>
          <span className="columns small-1"> {item.employee_number} </span>
          <span className="columns small-1 text-center"> {item.role.name == "ceo" ? "GF" : "VK"} </span>
          <span className="columns small-1"> {item.company_reference} </span>
          <span className="columns small-4"> {item.company_name} </span>
        </div>
      </label>
    </div>
    );
  }, areEqual);

  function renderRegion(region, index) {
    return (
      <div className="checkbox-list__item">
        <input
          id={`region_${region.id}`}
          type="checkbox"
          value="0"
          name={`region[${region.id}]`}
          checked={_.find(props.filtersChecked.hierarchy.regions, ['id', region.id]) ? 'checked' : ''}
          onChange={(e) => { props.handleUpdateFiltersRegion(region) }}
        />
        <label htmlFor={`region_${region.id}`}>
          {`Region ${region.identifier}`}
        </label>
      </div>
    )
  }

  function renderRole(role, index) {
    const roleName = (function (role) {
      switch (role) {
        case 'ceo':
          return 'Alle Geschäftsführer'
        case 'salesman':
          return 'Alle Verkäufer'
        case 'rio_incentive':
          return 'unbekannt'
        case 'salesman_club':
          return 'unbekannt'
        case 'premium_club':
          return 'unbekannt'
        default:
          return 'unbekannt'
      }
    })(role)

    return (
      <div className="checkbox-list__item">
        <input
          id={`role_${index}`}
          type="checkbox"
          value="0"
          name={`role[${index}]`}
          checked={props.filtersChecked.roles.find(roleName => roleName === role) ? 'checked' : ''}
          onChange={(e) => { props.handleUpdateFiltersRole(role) }}
        />
        <label htmlFor={`role_${index}`}>
          {roleName}
        </label>
      </div>
    )
  }

  return (
    <div className="notification-receiver">
      <div className="row">
        <div className="columns large-12 margin-bottom-l">
          Wählen Sie hier die Empfänger Ihrer Benachrichtigungen aus.
          Sie können sowohl zwischen verschiedenen Rollen, als auch individuell zusammengestellten und gespeicherten Zielgruppen wählen.
          Hierfür können Sie Teilnehmer über die Händlernummer suchen oder aber über die achtstellige Verkäufernummer.
          Die von Ihnen erstellte Benachrichtigung wird automatisch an alle Master Ihrer GmbH gesendet.
        </div>
      </div>
      <div className="row">
        {
          props.loadingEmployees.text &&
          <div className={`columns large-12 ${props.loadingEmployees.state === 'alert' ? 'error' : ''}`}>
            <div className={`callout ${props.loadingEmployees.state === 'alert' ? 'alert' : ''}`}>{props.loadingEmployees.text}</div>
          </div>
        }
        {
          props.message.state &&
          <div className={`columns large-12 ${props.message.state && 'error'}`}>
            <div className="callout alert">{props.message.text}</div>
          </div>
        }

        {
          <div className="columns large-12">
            <h2> Empfänger auswählen </h2>
            <div className="row notification-receiver__regions">
              {
                !_.isEmpty(props.filters.hierarchy) &&
                props.filters.hierarchy.regions.map((region, regionIndex) => {
                  return (
                    <div className="columns large-4" key={`region_${region.id}`}>
                      <div className="checkbox-list checkbox-list--white">
                        {
                          renderRegion(region, regionIndex)
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
      <div className="row">
        {
          !_.isEmpty(props.filters.roles) &&
          props.filters.roles.map((role, roleIndex) => {
            if (roleIndex < 2) {
              return (
                <div className="columns large-6 left" key={`role_${roleIndex}`}>
                  <div className="checkbox-list checkbox-list--grey">
                    {
                      renderRole(role, roleIndex)
                    }
                  </div>
                </div>
              )
            }
          })
        }
      </div>

      <div className="row notification-receiver__searchInput form-box">
        <div className="columns small-12">
          <h3 className="margin-top-l"> Ausgewählte Empfänger
            (<span className="notification-receiver--amount">{props.employeesChecked.length}</span> von <span className="notification-receiver--amount">{props.employees.length}</span>)
          </h3>
        </div>
        <div className="columns small-12 medium-9">
          <input
            type="text"
            value={props.searchTerm}
            name="searchTerm"
            id="searchTerm"
            placeholder="Suche nach Name, Verkäufernummer, etc. "
            onChange={(event) => { props.handleOnChangeSearchList(event.target.value, props.employeesChecked, props.employeesNotChecked) }}
          />
        </div>
        <div className="columns small-12 medium-3 notification-receiver--reset-filter">
          <a onClick={(event) => { props.handleOnClickResetFilter() }}>Auswahl zurücksetzen</a>
        </div>
      </div>
      <div className='row'>
        <div className='columns small-12'>
          <div className="notification-receiver__employeeList margin-bottom-l">
            <AutoSizer disableHeight>
              {({ width }) => (
                <React.Fragment>
                  <List
                    width={width}
                    height={84}
                    itemCount={1}
                    itemSize={80}
                  >
                    {Headline}
                  </List>
                  <List
                    width={width}
                    height={500}                    
                    itemCount={props.employeesShow.length}
                    itemSize={80}
                  >
                    {Row}
                  </List>
                </React.Fragment>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12">
          <div className="button-box button-box--space-between">
            <a className="button button--ghost" onClick={(event) => { props.handleOnChangeTab(event, 'step_1') }}> Zurück</a>
            <a className="button"
              disabled={props.loadingEmployees.text ? true : false}
              onClick={(event) => { props.loadingEmployees.text ? '' : props.handleOnChangeTab(event, 'step_3') }}
            >
              Weiter
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepTwo;
