import React from 'react';
import moment from 'moment';

import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';


const StepThree = (props) => {
  return (
    <div>
      <div className="row">
        {props.loadingSubmit.state &&
          <div className='columns large-12'>
            <div className={`callout ${props.loadingSubmit.state === 'info' ? '' : props.loadingSubmit.state}`}>{props.loadingSubmit.text}</div>
          </div>
        }
        <div className="columns large-3">
          <div className="notification-image--preview">
            <img src={props.imageSrc} />
          </div>
        </div>
        <div className="columns large-8 large-offset-1 margin-bottom">
          <h3> {props.subject} </h3>
          <FroalaEditorView
            model={props.model}
          />
        </div>
      </div>

      <div className="row">
        <div className="columns large-2 large-offset-4">
          <b> Beginnt am:</b>
        </div>
        <div className="columns large-6">
          <b> {props.start_date.format("DD.MM.YYYY")} </b>
        </div>
        <div className="columns large-2 large-offset-4">
          <b> Endet am: </b>
        </div>
        <div className="columns large-6 margin-bottom">
          <b> {props.end_date.format("DD.MM.YYYY")} </b>
        </div>
      </div>
      <div className="row">
        <div className="columns large-12">
          <div className="button-box button-box--space-between">
            <a className="button button--ghost" onClick={(event) => { props.handleOnChangeTab(event, 'step_2') }}> Zurück </a>
            <a className="button"
              disabled={props.loadingSubmit.state ? true : false}
              onClick={(event) => { if (!props.loadingSubmit.state) (props.current_path.indexOf('edit') > 0 ? props.handleUpdateNotification() : props.handleSubmitNotification()) }}
            >
              Senden
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepThree;